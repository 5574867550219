import "./outline-text-promo.scss";

import { bool, string } from "prop-types";
import { useRef, useState } from "react";

import cx from "classnames";
import useAOS from "../../common/useAOS.js";
import useMatchMedia from "../../common/useMatchMedia.js";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {
  textLine1: string.isRequired,
  textLine2: string,
  dark: bool,
};

const OutlineText = (props) => {
  const { textLine1, textLine2, dark } = props;

  useAOS();

  const promoRef = useRef();

  const isNotMobile = useMatchMedia("(min-width: 680px)"); // matches $bp-small-min in _breakpoints.scss

  const [shouldAnimate, setShouldAnimate] = useState(false);

  useScrollama(promoRef, {
    offset: 0.7,
    // debug: true,
    onStepEnter: (response) => setShouldAnimate(true),
    once: true,
  });

  return (
    <section ref={promoRef} className="outline-text-promo">
      <svg width="inherit" height="inherit" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="gradient" x1="0" x2="1">
            <stop stopColor="#7300e7" offset="0" />
            <stop stopColor="#f7453d" offset="1" />
          </linearGradient>
        </defs>
        {shouldAnimate && (
          <g>
            <text
              data-aos="slide-left"
              data-aos-duration="3000"
              id="text"
              className={cx("outline-text-promo__line1 outline-text", {
                "outline-text--dark": dark,
              })}
              y=".8em"
              strokeWidth="2"
              stroke="url(#gradient)"
              fill="transparent"
            >
              {textLine1}
            </text>
            {textLine2 && (
              <text
                data-aos="slide-right"
                data-aos-delay="300"
                data-aos-duration="3000"
                id="text"
                className={cx("outline-text-promo__line2 outline-text", {
                  "outline-text--dark": dark,
                })}
                y="1.8em"
                x={isNotMobile ? 100 : 40}
                strokeWidth="2"
                stroke="url(#gradient)"
                fill="transparent"
              >
                {textLine2}
              </text>
            )}
          </g>
        )}
      </svg>
    </section>
  );
};

OutlineText.propTypes = propTypes;
export default OutlineText;
