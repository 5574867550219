import HomePage from "../components/HomePage/HomePage.jsx";

const propTypes = {};

const GatsbyHomePage = (props) => {
  return <HomePage />;
};

GatsbyHomePage.propTypes = propTypes;
export default GatsbyHomePage;
