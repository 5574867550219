import "./home-page.scss";

import * as R from "ramda";

import { graphql, useStaticQuery } from "gatsby";
import { useRef, useState } from "react";

import Container100 from "../Container/Container100.jsx";
import ContainerBleed from "../Container/ContainerBleed.jsx";
import HomePageHero from "../HomePageHero/HomePageHero.jsx";
import OutlineTextPromo from "../OutlineTextPromo/OutlineTextPromo.jsx";
import Page from "../Page/Page.jsx";
import Promo3Up from "../Promo3Up/Promo3Up.jsx";
import Quote from "../Quote/Quote.jsx";
import SignUp from "../SignUp/SignUp.jsx";
import SlideShowBlock from "../SlideShow/SlideShowBlock.jsx";
import TwoUpTextBanner from "../TwoUpTextBanner/TwoUpTextBanner.jsx";
import extractHomePageHero from "../../common/extractHomePageHero.js";
import extractOutlineTextPromo from "../../common/extractOutlineTextPromo.js";
import extractPromo3Up from "../../common/extractPromo3Up.js";
import extractQuote from "../../common/extractQuote.jsx";
import extractSlideShow from "../../common/extractSlideShow.js";
import extractTimelinePromo from "../../common/extractTimelinePromo.js";
import extractTwoUpTextBanner from "../../common/extractTwoUpTextBanner.js";
import { useContentfulSiteMap } from "../../common/contentfulSiteMap.jsx";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {};

const HomePage = (props) => {
  const contentfulData = useStaticQuery(query);

  const siteMap = useContentfulSiteMap();

  const [gradientStop, setGradientStop] = useState(0);

  const data = R.compose(
    R.evolve({
      homePageHero: extractHomePageHero,
      homePageSlideShow: extractSlideShow,
      homePagePromo3Up: extractPromo3Up,
      homePageQuote: extractQuote,
      homePageTimelinePromo: extractTimelinePromo,
      homePageOutlineTextPromo: extractOutlineTextPromo,
      homePageTwoUpTextBanner: extractTwoUpTextBanner,
    }),
    R.defaultTo({}),
    R.prop("contentfulHomePage"),
  )(contentfulData);

  const {
    homePageHero,
    homePageSlideShow,
    homePagePromo3Up,
    homePageQuote,
    homePageTimelinePromo,
    homePageOutlineTextPromo,
    homePageTwoUpTextBanner,
  } = data;

  const colorStop1Ref = useRef();
  const colorStop2Ref = useRef();
  const colorStop3Ref = useRef();

  /* Scrollama Effects */
  useScrollama(colorStop1Ref, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setGradientStop(0),
  });

  useScrollama(colorStop2Ref, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setGradientStop(1),
  });

  useScrollama(colorStop3Ref, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setGradientStop(2),
  });

  return (
    <Page className="home-page" gradient={gradientStop}>
      <div ref={colorStop1Ref}>
        <HomePageHero {...homePageHero} />
        <SlideShowBlock {...homePageSlideShow} />
      </div>
      <div ref={colorStop2Ref}>
        <Promo3Up {...homePagePromo3Up} />
        <Container100>
          <Quote {...homePageQuote} />
        </Container100>
      </div>

      <div ref={colorStop3Ref}>
        <ContainerBleed>
          <OutlineTextPromo {...homePageOutlineTextPromo} />
        </ContainerBleed>

        <ContainerBleed>
          <SignUp
            title="Join Our Movement"
            subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
          />
        </ContainerBleed>

        <ContainerBleed>
          <TwoUpTextBanner {...homePageTwoUpTextBanner} />
        </ContainerBleed>
      </div>
    </Page>
  );
};

HomePage.propTypes = propTypes;
export default HomePage;

const query = graphql`
  {
    contentfulHomePage(id: { eq: "c6817ea2-ba0e-5362-8b2c-29bab803a76d" }) {
      homePageHero {
        headline
        description
        foregroundVideo {
          ...YouTubeVideo
        }
        backgroundVideo {
          file {
            url
          }
        }
      }
      homePageTimelinePromo {
        ...TimelinePromo
      }
      homePageSlideShow {
        ...SlideShow
      }
      homePagePromo3Up {
        promo3UpTitle
        promo3UpSubtitle {
          raw
        }
        promo1 {
          ...ImageBlock
        }
        promo2 {
          ...ImageBlock
        }
        promo3 {
          ...ImageBlock
        }
      }
      homePageQuote {
        ...Quote
      }
      homePageOutlineTextPromo {
        ...OutlineTextPromo
      }
      homePageTwoUpTextBanner {
        ...TwoUpTextBanner
      }
    }
  }
`;
