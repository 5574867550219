import "./promo-3-up.scss";

import * as R from "ramda";

import { arrayOf, node, object, shape, string } from "prop-types";
import { useRef, useState } from "react";

import Container100 from "../Container/Container100.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import PlusSigns from "../PlusSigns/PlusSigns.jsx";
import cx from "classnames";
import { navigate } from "gatsby";
import { tagFields } from "../../common/extractTag.js";
import useAOS from "../../common/useAOS";
import { useContentfulSiteMap } from "../../common/contentfulSiteMap.jsx";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {
  title: string.isRequired,
  subtitle: node,
  theme: string,
  cards: arrayOf(
    shape({
      title: string.isRequired,
      image: node.isRequired,
      text: node.isRequired,
      link: object.isRequired, //conteful link validates
      tag: shape(tagFields),
    })
  ).isRequired,
};

const Promo3Up = (props) => {
  const { title, subtitle, cards, theme } = props;

  const siteMap = useContentfulSiteMap();

  const [shouldAnimate, setShouldAnimate] = useState(false);

  const promo3UpRef = useRef();

  useAOS();

  useScrollama(promo3UpRef, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setShouldAnimate(true),
    once: true,
  });

  const handleClick = (link) => {
    const path = link.to || R.path([link.pageId, "path"])(siteMap);
    if (!R.isNil(path)) {
      // for external and media links, open a in a new window
      if (link.type === "external") {
        window.open(path, "_blank");
      }
      // for internal/page links, navigate regularly
      else {
        navigate(path);
      }
    }
  };

  return (
    <section
      ref={promo3UpRef}
      className={cx("promo-3-up", {
        "promo-3-up--animated": shouldAnimate,
        [`promo-3-up--${theme}`]: theme,
      })}
    >
      <Container100>
        <PlusSigns />
        {title && (
          <h2 data-aos="fade-down" className="promo-3-up__title">
            <strong>{title}</strong>
          </h2>
        )}
        {subtitle && (
          <div data-aos="fade-down" className="promo-3-up__description">
            {subtitle}
          </div>
        )}
        <div className="promo-3-up__cards">
          {cards.map((card, i) => (
            <div
              key={i}
              data-aos="fade-down"
              className="promo-3-up__card is-clickable"
            >
              <div className="promo-3-up__number outline-text outline-text">{`0${
                i + 1
              }`}</div>
              <div
                role="presentation"
                className="promo-3-up__content"
                tabIndex="-1"
                onClick={() => handleClick(card.link)}
              >
                <div
                  className="promo-3-up__img-wrapper"
                  style={{ "--tag-color": card.tag?.color?.colorValue }}
                >
                  {card.image}
                </div>
                <div className="promo-3-up__card-content">
                  <div className="promo-3-up__card-title">{card.title}</div>
                  {card.text && (
                    <div className="promo-3-up__card-description">
                      {card.text}
                    </div>
                  )}
                  {card.link && (
                    <ContentfulLink
                      className="promo-3-up__cta btn btn--big"
                      {...card.link}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container100>
      <PlusSigns isLeft={true} />
    </section>
  );
};

Promo3Up.propTypes = propTypes;
export default Promo3Up;
