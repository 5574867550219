import * as R from "ramda";

export default R.compose(
  R.applySpec({
    headline: R.prop("headline"),
    description: R.prop("description"),
    foregroundVideoId: R.path(["foregroundVideo", "videoId"]),
    backgroundVideoUrl: R.path(["backgroundVideo", "file", "url"]),
  }),
  R.defaultTo({}),
);
