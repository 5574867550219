import "./home-page-hero.scss";

import { useRef, useState } from "react";

import Button from "../Button/Button.jsx";
import Modal from "../Modal/Modal.jsx";
import YouTube from "react-youtube";
import { string } from "prop-types";
import useAOS from "../../common/useAOS.js";

const propTypes = {
  headline: string.isRequired,
  description: string,
  foregroundVideoId: string.isRequired,
  backgroundVideoUrl: string.isRequired,
};

const HomePageHero = (props) => {
  const { headline, description, foregroundVideoId, backgroundVideoUrl } =
    props;

  const bgVideoRef = useRef();
  const [isBgVideoPaused, setIsBgVideoPaused] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [player, setPlayer] = useState(null);

  useAOS();

  function handlePlayPause(ref) {
    if (ref.current) {
      if (ref.current.paused) {
        ref.current.play();
        setIsBgVideoPaused(false);
      } else {
        ref.current.pause();
        setIsBgVideoPaused(true);
      }
    }
  }

  return (
    <section className="home-page-hero container container--bleed container--has-background">
      <div className="home-page-hero__content-wrapper">
        <div className="container container--100">
          <h1
            data-aos="fade-down"
            data-aos-offset="0"
            className="home-page-hero__title"
          >
            <strong>{headline}</strong>
          </h1>
          <div
            data-aos="fade-down"
            data-aos-offset="0"
            className="home-page-hero__description"
          >
            {description}
          </div>
          {foregroundVideoId && (
            <Button
              onClick={() => setModalOpen(true)}
              className="home-page-hero__cta btn btn--big"
            >
              Watch the video
            </Button>
          )}
        </div>
        {backgroundVideoUrl && (
          <button
            className="home-page-hero__play-toggle-btn"
            aria-label={isBgVideoPaused ? "play" : "pause"}
            type="button"
            onClick={() => handlePlayPause(bgVideoRef)}
          >
            {isBgVideoPaused ? playSvg : pauseSvg}
          </button>
        )}
      </div>
      {backgroundVideoUrl && (
        <div className="home-page-hero__video-wrapper">
          <video
            ref={bgVideoRef}
            className="home-page-hero__bg-video"
            muted
            loop
            autoPlay
            src={backgroundVideoUrl}
          ></video>
        </div>
      )}
      {foregroundVideoId && (
        <Modal
          className="home-page-hero__modal"
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            player && player.pauseVideo();
          }}
        >
          <YouTube
            className="you-tube-video"
            videoId={foregroundVideoId}
            onReady={(event) => setPlayer(event.target)}
          />
        </Modal>
      )}
      <div aria-hidden className="home-page-hero__accent">
        <div
          data-aos="slide-right"
          data-aos-duration="1000"
          data-aos-delay="600"
          data-aos-offset="0"
          className="home-page-hero__accent-bar home-page-hero__accent-bar--1"
        >
          <div />
        </div>
        <div
          data-aos="slide-right"
          data-aos-duration="1000"
          data-aos-delay="300"
          data-aos-offset="0"
          className="home-page-hero__accent-bar home-page-hero__accent-bar--2"
        >
          <div />
        </div>
        <div
          data-aos="slide-right"
          data-aos-duration="1000"
          data-aos-offset="0"
          className="home-page-hero__accent-bar home-page-hero__accent-bar--3"
        >
          <div />
        </div>
      </div>
    </section>
  );
};

HomePageHero.propTypes = propTypes;
export default HomePageHero;

const playSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-play-circle"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <polygon points="10 8 16 12 10 16 10 8"></polygon>
  </svg>
);

const pauseSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-pause-circle"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="10" y1="15" x2="10" y2="9"></line>
    <line x1="14" y1="15" x2="14" y2="9"></line>
  </svg>
);
